import React from 'react';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStarted from 'components/GetStarted';
// import { Usecases } from 'styles/usecases';
import FAQ from '../../components/Faq';
import FeatureContainer from '../../components/FeatureContainer';
import { signUp } from '../../utils/contentProvider/function';
import TrustedBy from '../../components/TrustedBy';
import Feedback from '../../components/Feedback/Feedback';
import TestimonialsSlider from '../../components/TestimonialsSlider/TestimonialsSlider';
import 'styles/usecases.scss';

const forDesignerFaq = [
  {
    q: 'How to use ruttl?',
    a: 'Sign up, then create a project. Paste your website URL and add it to this project. Share the project with your team by adding their email addresses. Once the page loads on ruttl, it is in comment mode by default. Click to add comments, tag other team members in comments using ‘@’, resolve or delete comments. Switch to edit mode to move elements, replace images, edit text, change background colours, modify opacities, etc. These edits can be resolved once done, thereby speeding up your web review process.',
  },
  {
    q: 'Can developers access website code after design changes are executed?',
    a: 'After you use the inspect feature to edit and make required changes to the website design, your developers can simply copy the entire altered code under the CSS tab in the Activities Panel. This can then be pasted directly into the code of live website files.',
  },
  {
    q: 'Which websites does ruttl work with?',
    a: 'ruttl works with static, WordPress, Vue.js, and React sites for collaborating on visual website feedback. In fact, you can use ruttl to work on pretty much every website. Just paste the website URL and get started!',
  },
  {
    q: 'Do clients need to have an account on ruttl to view projects?',
    a: 'Absolutely not! Once your project is ready to share, simply click on the share button inside the project page. In the new screen, click on the ‘Link’ text. The shareability toggle should be turned on and the link will be copied to your clipboard. Now all you need to do is share the link with your clients to get the client feedback on the project.',
  },
  {
    q: 'Does ruttl support task management?',
    a: (
      <>
        No, ruttl does not support task management. If you’re looking for the
        same, we would recommend checking out
        <a href="https://brutask.com" target="_blank" rel="noopener noreferrer">
          &nbsp;Brutask&nbsp;
        </a>
        . It is a simple online to-do list for individuals and small teams. Add,
        prioritize and time box your tasks to help improve your productivity.
      </>
    ),
  },

  {
    q: 'Will I get recommendations on my website’s look and feel?',
    a: (
      <>
        ruttl will not be able to recommend anything about your website’s look
        and feel. We would suggest you to please contact
        <a href="https://brucira.com" target="_blank" rel="noopener noreferrer">
          &nbsp;Brucira&nbsp;
        </a>
        by mailing us at
        <a
          href="mailto:hello@brucira.com"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;hello@brucira.com&nbsp;
        </a>
        .
      </>
    ),
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));
const ForDesignersDevelopers = ({ location }) => (
  <Layout location={location}>
    {({ toggleContactUs, toggleSignup }) => (
      <>
        <Meta
          title="The #1 Website Feedback Tool for Desginers and Developers! | ruttl"
          description="Struggling to collect feedback? Try Ruttl - the fastest website feedback and bug tracking tool to review developed websites, web apps and mobile apps!"
          url="https://ruttl.com/usecases/for-designers-and-developers/"
        />
        <main className="usecases-styled-main">
          <section className="features-hero custom-padding grey-bg">
            <div className="container">
              <h1>
                Get quicker approvals and launches with the help of visual
                feedback
              </h1>
              <p>
                Let your clients show you exactly what they want with precise
                feedback annotations and video comments
              </p>
              <div
                className="reveal-1 button-container"
                style={{ margin: '30px 0' }}>
                <a href="https://web.ruttl.com" className="button">
                  Get Started for Free
                </a>
              </div>
            </div>
            <TrustedBy />
          </section>
          <div className="section features-main" id="features">
            <div className="container">
              <h2 className="center mt-40">Features</h2>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <video
                        src="/assets/img/guest-commenting.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div className="feature-txt">
                      <h2 className="feature-head">
                        Get precise feedback from clients
                      </h2>
                      <p>
                        Be it animation alignment or font size, clients can
                        suggest accurate values for all the iterations and make
                        communication simpler. No more sending tens of
                        screenshots to get a single point across.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Edit your Live Website
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <video
                        src="/assets/img/edit-text.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div className="feature-txt">
                      <h2 className="feature-head">
                        Edit website content in real-time
                      </h2>
                      <p>
                        Make seamless content changes on your website in
                        real-time and save time on making and revising offline
                        drafts.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Edit Website Content
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <img
                        src="/assets/img/show-activities.png"
                        alt="ruttl feature to inspect CSS code"
                        title="ruttl feature to inspect CSS code"
                      />
                    </div>
                    <div className="feature-txt">
                      <h2 className="feature-head">Inspect CSS easily</h2>
                      <p>
                        Edit on live websites in a click by viewing clean CSS.
                        Check for accessibility issues, redlines, and other
                        technical essentials without having to go through
                        lengthy development cycles.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Inspecting
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/share-unlimited-access.png"
                          alt="illustration of comments resolved after done"
                          title="illustration of comments resolved after done"
                        />
                      </div>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-13px)' }}>
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '360px' }}>
                        Invite your clients to leave comments
                      </h2>
                      <p style={{ maxWidth: '380px' }}>
                        With a quick shareability link, allow clients to go
                        through different versions of the website and leave
                        their suggestions. No hassles of signing up and logging
                        in!
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Invite Your Clients
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/leave-video-comments.png"
                          alt="illustration of video comments"
                          title="illustration of video comments"
                        />
                      </div>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-30px)' }}>
                      <h2 className="feature-head">Allow for video feedback</h2>
                      <p>
                        Give clients an option of giving detailed feedback by
                        letting them record video feedback for any animation
                        design or website development issue.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Try Video Feedback
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <img
                        src="/assets/img/record-version.png"
                        alt="illustration of leaving comment on live website"
                        title="illustration of leaving comment on live website"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-30px)' }}>
                      <h2 className="feature-head">
                        Handle multiple projects easily
                      </h2>
                      <p>
                        See all the changes happening in the Activities panel
                        and never lose track of ongoing activities when you are
                        working on multiple projects.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Check the Activities Panel
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <video
                        src="/assets/img/guest-commenting.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-10px)' }}>
                      <h2 className="feature-head">
                        Faster approvals and client satisfaction
                      </h2>
                      <p>
                        By better collaboration, visual feedback, tagging people
                        in comments, you can be sure of getting faster approvals
                        and launching client websites faster leading to
                        increased customer satisfaction.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Get Approvals Faster
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
            </div>
          </div>
          <TestimonialsSlider greybg />
          <Feedback />

          <FAQ
            headingCTA
            toggleContactUs={toggleContactUs}
            greyBg
            data={forDesignerFaq}
          />
          <div className="get-started-center">
            <GetStarted toggleSignup={toggleSignup} />
          </div>
        </main>
      </>
    )}
  </Layout>
);

ForDesignersDevelopers.propTypes = {
  location: PropTypes.object,
};

export default ForDesignersDevelopers;
