import React from 'react';
import cn from 'clsx';
// import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { btnOnClick } from '../../utils/contentProvider/function';
import {
  MixPanelEvents,
  MixPanelButtons,
  MixPanelButtonContext,
  PostHogEvents,
  PostHogButtons,
  PostHogButtonContext,
} from '../../constants';

import 'styles/components/FeedBack.scss';

const Feedback = ({ greybg }) => (
  <div className={cn(['feedback-div', { greybg }])}>
    <div className="container">
      <div className="flex align-items-stretch flex-wrap">
        <div className="flex-1">
          <div className="feedback-main">
            <div className="flex justify-content-between column">
              <div className="feedback-content">
                <h2>
                  The All-In-One Feedback Tool You&apos;ve Been Searching For
                </h2>
                <p>
                  No matter what type of visual project you are working on,
                  ruttl acts a digital whiteboard that you can use to share
                  precise digital feedback.
                </p>
              </div>
              <img
                loading="lazy"
                src="/assets/img/index-feedback.svg"
                alt="hand holding happy face frame illustration"
                title="hand holding happy face frame illustration"
                className="feedback-image"
                width="358"
                height="239"
              />
            </div>
          </div>
        </div>
        <div className="flex-2 flex flex-wrap align-items-stretch">
          <div className="wrapper">
            <div className="card-single">
              <div className="card-block">
                <div>
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    title="responsive websites icon"
                    src="/assets/video/review-websites.webm"
                  />
                </div>
                <h3>Review Websites</h3>
                <p>
                  Add any URL and start collecting precise feedback with visual
                  comments.
                  <br />
                  <br />
                </p>
                <button
                  type="button"
                  className="button button-small"
                  onClick={btnOnClick({
                    mixPanelEvent: MixPanelEvents.ButtonClick,
                    to: '/website-feedback-tool/',
                    config: {
                      Button: MixPanelButtons.GetStarted,
                      Context: MixPanelButtonContext.Feedback1,
                    },
                  })}>
                  Learn more
                  {/* <svg width="20" height="20" viewBox="0 0 512 512">
                    <path
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="48"
                      d="M268 112l144 144-144 144M392 256H100"></path>
                  </svg> */}
                </button>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <div className="card-single">
              <div className="card-block">
                <div>
                  <video
                    autoPlay
                    loop
                    muted
                    title="bug tracking animated icon"
                    playsInline
                    src="/assets/video/bug-tracking-maintainance.webm"
                  />
                </div>
                <h3 className="link-tag" data-value="New">
                  Bug Tracking
                </h3>
                <p>
                  Easily track &amp; monitor bugs or issues within your web
                  project.
                  <br />
                  <br />
                </p>
                <button
                  type="button"
                  className="button button-small"
                  onClick={btnOnClick({
                    mixPanelEvent: MixPanelEvents.ButtonClick,
                    posthogEvent: PostHogEvents.ButtonClick,
                    to: '/bug-tracking-tool/',
                    config: {
                      Button: MixPanelButtons.GetStarted,
                      Context: MixPanelButtonContext.Feedback2,
                    },
                    posthogConfig: {
                      Button: PostHogButtons.GetStarted,
                      Context: PostHogButtonContext.Feedback2,
                    },
                  })}>
                  Learn more
                  {/* <svg width="20" height="20" viewBox="0 0 512 512">
                    <path
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="48"
                      d="M268 112l144 144-144 144M392 256H100"></path>
                  </svg> */}
                </button>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <div className="card-single">
              <div className="card-block">
                <div>
                  <video
                    autoPlay
                    loop
                    muted
                    title="pencil hovering over square animated icon"
                    playsInline
                    src="/assets/video/review-web-apps.webm"
                  />
                </div>
                <h3 className="link-tag" data-value="New">
                  Review Web Apps
                </h3>
                <p>
                  Edit web applications &amp; review them with the team, using
                  our Chrome extension.
                  <br />
                  <br />
                </p>
                <button
                  type="button"
                  className="button button-small"
                  onClick={btnOnClick({
                    mixPanelEvent: MixPanelEvents.ButtonClick,
                    posthogEvent: PostHogEvents.ButtonClick,
                    to: '/features/review-web-applications/',
                    config: {
                      Button: MixPanelButtons.GetStarted,
                      Context: MixPanelButtonContext.Feedback3,
                    },
                    posthogConfig: {
                      Button: PostHogButtons.GetStarted,
                      Context: PostHogButtonContext.Feedback3,
                    },
                  })}>
                  Learn more
                  {/* <svg width="20" height="20" viewBox="0 0 512 512">
                    <path
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="48"
                      d="M268 112l144 144-144 144M392 256H100"></path>
                  </svg> */}
                </button>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <div className="card-single">
              <div className="card-block">
                <div>
                  <video
                    autoPlay
                    loop
                    muted
                    title="people connected to each other animated icon"
                    playsInline
                    src="/assets/video/pdf-and-image-annotation.webm"
                  />
                </div>
                <h3>PDF and Image Annotation</h3>
                <p>
                  Markup PDF&apos;s and images visually by annotating and
                  leaving real-time comments on your documents.
                  <br />
                  <br />
                </p>
                <button
                  type="button"
                  className="button button-small"
                  onClick={btnOnClick({
                    mixPanelEvent: MixPanelEvents.ButtonClick,
                    posthogEvent: PostHogEvents.ButtonClick,
                    to: '/online-pdf-annotator-markup-software/',
                    config: {
                      Button: MixPanelButtons.GetStarted,
                      Context: MixPanelButtonContext.Feedback4,
                    },
                    posthogConfig: {
                      Button: PostHogButtons.GetStarted,
                      Context: PostHogButtonContext.Feedback4,
                    },
                  })}>
                  Learn more
                  {/* <svg width="20" height="20" viewBox="0 0 512 512">
                    <path
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="48"
                      d="M268 112l144 144-144 144M392 256H100"></path>
                  </svg> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Feedback.propTypes = {
  greybg: PropTypes.bool,
};
Feedback.defaultProps = {
  greybg: false,
};

export default Feedback;
